import { ISettingsParam } from '@wix/yoshi-flow-editor/tpa-settings';

import { ButtonType } from '../../../common/settings/groups-list/settingsConstants';

import {
  CoverImageLayout,
  MediaImagesPerRow,
  MediaLayout,
} from './settingsConstants';
import { transformSettingsKey } from '../../../common/utils/utils';

export type ISettingsParams = {
  /**
   * Design settings
   */
  buttonType: ISettingsParam<ButtonType>;

  /**
   * General layout settings
   */
  coverImageLayout: ISettingsParam<CoverImageLayout>;
  coverImageHeight: ISettingsParam<number>;
  layoutSpacing: ISettingsParam<number>;

  /**
   * Media layout settings
   */
  mediaLayout: ISettingsParam<MediaLayout>;
  imagesPerRow: ISettingsParam<MediaImagesPerRow>;

  /**
   * Discussions display settings
   */
  showReactions: ISettingsParam<boolean>;
  showMembersCount: ISettingsParam<boolean>;
  showShareButton: ISettingsParam<boolean>;
  showBreadcrumbs: ISettingsParam<boolean>;

  /**
   * Media display settings
   */
  showNameOnHover: ISettingsParam<boolean>;

  /**
   * Members display settings
   */
  showFilters: ISettingsParam<boolean>;
  showContent: ISettingsParam<boolean>;
  showFollowButton: ISettingsParam<boolean>;

  /**
   * About display settings
   */
  showActivity: ISettingsParam<boolean>;

  /**
   * Text settings
   */
  newPostBoxLabel: ISettingsParam<string>;
  showMembersBadges: ISettingsParam<boolean>;
};

export const settingsParams: ISettingsParams = {
  /**
   * Design settings
   */
  buttonType: {
    key: 'buttonType',
    getDefaultValue: () => ButtonType.rounded,
  },

  /**
   * General layout settings
   */
  coverImageHeight: {
    getDefaultValue: () => 240,
    key: 'coverImageHeight',
    inheritDesktop: false,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  layoutSpacing: {
    getDefaultValue: () => 20,
    key: 'layoutSpacing',
    inheritDesktop: false,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  coverImageLayout: {
    getDefaultValue: () => CoverImageLayout.large,
    key: 'coverImageLayout',
    inheritDesktop: false,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  /**
   * Media layout settings
   */
  imagesPerRow: {
    getDefaultValue: () => 5,
    key: 'imagesPerRow',
  },
  mediaLayout: {
    getDefaultValue: () => MediaLayout.grid,
    key: 'mediaLayout',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  /**
   * Discussions display settings
   */
  showBreadcrumbs: {
    key: 'showBreadcrumbs',
    getDefaultValue: () => false,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showMembersCount: {
    key: 'showMembersCount',
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showReactions: {
    key: 'showReactions',
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showShareButton: {
    key: 'showShareButton',
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  /**
   * Media display settings
   */
  showNameOnHover: {
    key: 'showNameOnHover',
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  /**
   * Members display settings
   */
  showContent: {
    key: 'showContent',
    getDefaultValue: () => false,
  },
  showFilters: {
    key: 'showFilters',
    getDefaultValue: () => false,
  },
  showFollowButton: {
    key: 'showFollowButton',
    getDefaultValue: () => false,
  },

  /**
   * About display settings
   */
  showActivity: {
    getDefaultValue: () => true,
    key: 'showActivity',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  /**
   * Text settings
   */
  newPostBoxLabel: {
    getDefaultValue: () => '',
    key: 'newPostBoxLabel',
  },
  showMembersBadges: {
    getDefaultValue: () => true,
    key: 'showMembersBadges',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
};
