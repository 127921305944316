import React from 'react';
import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ApiTypesV1GroupResponse, GroupWrapper } from '@wix/social-groups-api';

import { getMembersLabelWithCount } from '../MembersLabel/MembersLabelWithCount';

import { classes, st } from './MembersCount.st.css';

export const MEMBERS_COUNT = 'members-count';

interface IMembersCountProps {
  group: ApiTypesV1GroupResponse;
  className?: string;
}

export const MembersCount: React.FC<IMembersCountProps> = (props) => {
  const { t } = useTranslation();

  const groupWrapper = new GroupWrapper(props.group);
  const membersCount = groupWrapper.getMembersCount();
  const membersLabel = groupWrapper.getMembersName();
  const label = getMembersLabelWithCount(membersLabel, membersCount, t);
  return (
    <Text
      data-hook={MEMBERS_COUNT}
      className={st(classes.root, {}, props.className)}
    >
      {label}
    </Text>
  );
};
