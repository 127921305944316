import React from 'react';

import { Grid } from 'wix-ui-tpa';
import {
  ApiTypesV1GroupMemberResponse,
  memberWrapper,
} from '@wix/social-groups-api';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { MemberCard } from '../../MemberCard/MemberCard';
import { TPAModalProps } from '../../../../../common/components/Modal/TPAModal';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { ModalV2 } from '../../../../../common/components/Modal/ModalV2';
import { FollowButton } from '../../FollowButton/FollowButton';
import { compose } from '../../../../../common/utils/compose';
import { classes, st } from './UsersJoinedModal.st.css';

interface UserJoinedModalProps extends TPAModalProps {
  members: ApiTypesV1GroupMemberResponse[];
}

type UserJoinedModalComponentProps = UserJoinedModalProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps;

const UserJoinedModalComponent: React.FC<UserJoinedModalComponentProps> = (
  props,
) => {
  const { onRequestClose, isOpen, members, mobile } = props;

  const { t } = useTranslation();

  return (
    <ModalV2 onRequestClose={onRequestClose} isOpen={isOpen}>
      <ModalV2.Title>{t('groups-web.users-joined-popup.title')}</ModalV2.Title>
      <ModalV2.Content className={st(classes.contentWrapper)}>
        {members.map((m, i) => {
          const { name, imageUrl } = memberWrapper(m);
          return (
            <div
              key={m.siteMemberId}
              className={st(classes.memberCardWrapper, { mobile } as any)}
            >
              <Grid.Item colSpan={1} rowSpan={1}>
                <MemberCard
                  name={name!.nick || t('groups-web.member.anonymous')}
                  image={imageUrl}
                  withDivider={!mobile && i !== members.length - 1}
                  action={<FollowButton siteMemberId={m.siteMemberId!} />}
                />
              </Grid.Item>
            </div>
          );
        })}
      </ModalV2.Content>
    </ModalV2>
  );
};

const enhance = compose(withSiteMembers, withTpaComponentsConfig);

export const UserJoinedModal = enhance(
  UserJoinedModalComponent,
) as React.ComponentType<UserJoinedModalProps>;

UserJoinedModal.displayName = 'UserJoinedModal';
