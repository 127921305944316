import React from 'react';
import moment from 'moment';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Avatar, AvatarSize, Text, TextButton } from 'wix-ui-tpa';
import { RelationshipWithGroup, RoleInGroup } from '@wix/social-groups-api';

import { st, classes } from './AuthorInfo.st.css';
import { ActivityStatus } from '../ActivityPost';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from 'Group/contexts/TPAComponent/withTpaComponentsConfig';
import { getAllowedRoles } from 'common/components/RoleBadges/helpers';
import { RoleBadges } from 'common/components/RoleBadges/RoleBadges';
import { IFeedItemActivity } from 'Group/types/IFeedItem';
import { MemberBadges } from '../MemberBadges/MemberBadges';

import { skeleton } from 'common/components/Skeleton';

interface AuthorInfoProps {
  avatarUrl: string;
  activity?: IFeedItemActivity;
  name: string;
  timeStamp: Date;
  relationship: RelationshipWithGroup;
  roles: RoleInGroup[];
  onClick(): void;
  badges: string[];
}

export const AuthorInfoComponent: React.FC<
  AuthorInfoProps & WithTpaComponentsConfigProps
> = ({
  avatarUrl,
  name,
  timeStamp,
  mobile,
  relationship,
  roles,
  activity,
  onClick,
  badges,
}) => {
  const allowedRoles = getAllowedRoles(roles);
  return (
    <div className={st(classes.root, { mobile } as any)}>
      <div onClick={onClick}>
        <Avatar
          size={mobile ? AvatarSize.medium : AvatarSize.large}
          src={avatarUrl}
          className={classes.avatar}
        />
      </div>
      <div className={classes.avatarDivider} />
      <div>
        <TextButton onClick={onClick} className={classes.name}>
          {name}
        </TextButton>
        {activity && (
          <>
            <div className={classes.titleDivider} />
            <ActivityStatus activity={activity} />
          </>
        )}
        <div className={classes.description}>
          {allowedRoles.length ? (
            <>
              <RoleBadges relationship={relationship} roles={allowedRoles} />
              <div className={classes.subtitleDivider} />
            </>
          ) : null}
          <Text className={classes.timestamp}>
            {moment(timeStamp).fromNow()}
            <MemberBadges badges={badges} />
          </Text>
        </div>
      </div>
    </div>
  );
};

export function AuthorInfoSkeleton() {
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobile: isMobile } as any)}>
      <div>
        <Avatar
          size={isMobile ? AvatarSize.medium : AvatarSize.large}
          className={cls(classes.avatar, skeleton)}
        />
      </div>
      <div className={classes.avatarDivider} />
      <div>
        <Text className={skeleton}>Lorem ipsum dolor sit.</Text>
        <div className={classes.titleDivider} />
        <div>
          <Text className={skeleton}>Lorem ipsum dolor.</Text>
        </div>
      </div>
    </div>
  );
}

export const AuthorInfo = withTpaComponentsConfig(AuthorInfoComponent);
