import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ButtonPriority } from 'wix-ui-tpa';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  useController,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { DetailsComponent } from 'Group/Widget/Details/Details';
import { IControllerActions } from 'FeedWidget/types';
import settingsParams from 'FeedWidget/settingsParams';

import { Button } from 'common/components/Button';
import { useDidUpdate } from 'common/hooks';

import { IGroupState, IStore } from '../../store/types';

import { classes, st } from './GroupDetails.st.css';

export function GroupDetails() {
  const { t } = useTranslation();
  const { methods } = useController() as IControllerActions;
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const group = useSelector<IStore, IGroupState>((state) => state.group);
  const groupId = settings.get(settingsParams.groupId);

  useDidUpdate(() => {
    methods.group.fetchGroup(groupId);
  }, [groupId]);

  if (!group.groupId || group.loading) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.details}>
        {/* @ts-expect-error */}
        <DetailsComponent group={group} />
      </div>
      <div>
        <Button
          fullWidth={isMobile}
          priority={ButtonPriority.primary}
          onClick={() => methods.group.redirectToGroup()}
        >
          {t('groups-web.go-to-group.label')}
        </Button>
      </div>
    </div>
  );
}
