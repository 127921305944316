import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { Block, BlockFlow, BlockProps } from '../Block';
import { PlaceholderTitle } from './Placeholders/PlaceholderCard';

import { st, classes } from './Card.st.css';

export interface CardTextProps {
  subtitle?: string;
  title?: React.ReactNode;
  dataHook?: string;
  className?: string;
  reverse?: boolean;
  ellipsis?: boolean;
  bw?: boolean;
  withPlaceholder?: boolean;
}

export const CardText: React.FC<CardTextProps & BlockProps> = (props) => {
  const {
    subtitle,
    title,
    dataHook,
    reverse = false,
    ellipsis = false,
    bw = false,
    withPlaceholder,
    ...rest
  } = props;
  const subtitleText = subtitle && (
    <Text typography={TextTypography.runningText} className={classes.subtitle}>
      {subtitle}
    </Text>
  );
  const placeholder = withPlaceholder && (
    <PlaceholderTitle className={classes.placeholderTitle}>
      {subtitleText}
    </PlaceholderTitle>
  );
  return (
    <Block
      flow={BlockFlow.row}
      {...rest}
      className={st(classes.root, { reverse, ellipsis, bw }, rest.className)}
    >
      <Text
        typography={TextTypography.runningText}
        data-hook={dataHook}
        className={classes.title}
      >
        {title || placeholder}
      </Text>

      {subtitleText || placeholder}
    </Block>
  );
};

CardText.displayName = 'CardText';
