import React from 'react';
import { useSelector } from 'react-redux';
import { useController, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { ButtonPriority } from 'wix-ui-tpa';

import { FeedItemSkeleton } from 'FeedWidget/Widget/Feed/FeedItem';
import { feedSelector } from 'FeedWidget/Widget/Feed/selectors';
import settingsParams from 'FeedWidget/settingsParams';
import { Button } from 'common/components/Button';

export function Footer() {
  const { t } = useTranslation();
  const settings = useSettings();
  const { methods } = useController();
  const { nextCursor } = useSelector(feedSelector);

  const limit = settings.get(settingsParams.postsCount);

  if (limit) {
    return (
      <Button
        priority={ButtonPriority.secondary}
        onClick={() => methods.group.redirectToGroup()}
      >
        {t('groups-web.feed.show-more.label')}
      </Button>
    );
  }

  return nextCursor ? (
    <>
      <FeedItemSkeleton />
      <FeedItemSkeleton />
    </>
  ) : null;
}
