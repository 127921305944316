import React from 'react';

import { Text } from 'wix-ui-tpa';

import { WithTpaComponentsConfigProps } from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { st, classes } from './ActivityStatus.st.css';

import { UsersJoined } from './UsersJoined';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { compose } from '../../../../../common/utils/compose';
import { withTPAConfig } from '../../../../../common/components/withTPAConfig';
import {
  FeedItemActivityType,
  IFeedItemActivity,
} from '../../../types/IFeedItem';

interface ActivityStatusProps {
  activity: IFeedItemActivity;
}

interface ActivityStatusComponentProps
  extends ActivityStatusProps,
    WithTpaComponentsConfigProps,
    WithTranslation {}

class ActivityStatusComponent extends React.Component<ActivityStatusComponentProps> {
  private readonly statusRenderMap: {
    [key in FeedItemActivityType]: () => React.ReactNode;
  };

  constructor(props: ActivityStatusComponentProps) {
    super(props);
    // TODO: ???
    this.statusRenderMap = {
      [FeedItemActivityType.DRAFT_JS]: () => this.renderDraftJSActivity(),
      [FeedItemActivityType.GROUP_COVER_CHANGED]: () =>
        this.renderGroupCoverChanged(),
      [FeedItemActivityType.ABOUT_GROUP_CHANGED]: () =>
        this.renderGroupAboutChanged(),
      [FeedItemActivityType.USERS_JOINED]: () => this.renderUsersJoined(),
    };
  }

  render() {
    const statusElement =
      this.statusRenderMap[this.props.activity.activityType];
    if (!statusElement) {
      return null;
    }
    return statusElement();
  }

  renderDraftJSActivity() {
    const { activity } = this.props.activity.data;
    switch (activity) {
      case 'event_added':
        return this.renderEventAdded();

      default:
        return '';
    }
  }

  renderGroupCoverChanged() {
    const { activity, t, mobile } = this.props;
    const { hasCoverBefore } = activity.data;

    const key = hasCoverBefore
      ? 'groups-web.activity-post.cover-image.updated'
      : 'groups-web.activity-post.cover-image.added';
    return (
      <Text className={st(classes.root, { mobile } as any)}>{t(key)}</Text>
    );
  }

  renderGroupAboutChanged() {
    const { t, mobile } = this.props;
    return (
      <Text className={st(classes.root, { mobile } as any)}>
        {t('groups-web.activity-post.about.changed')}
      </Text>
    );
  }

  renderEventAdded() {
    const { t, mobile } = this.props;
    return (
      <Text className={st(classes.root, { mobile } as any)}>
        {t('groups-web.activity-post.event.added')}
      </Text>
    );
  }

  renderUsersJoined() {
    return <UsersJoined {...this.props.activity.data} />;
  }
}

const enhance = compose(withTranslation(), withTPAConfig);

export const ActivityStatus = enhance(
  ActivityStatusComponent,
) as React.ComponentType<ActivityStatusProps>;
