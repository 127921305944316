import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../Settings/settingsParams';
import { MEMBERS_BADGES } from '../dataHooks';

interface MemberBadgesProps {
  badges: string[];
}

export const MemberBadges: React.FC<MemberBadgesProps> = ({ badges }) => {
  const settings = useSettings();

  const showMembersBadges = settings.get(settingsParams.showMembersBadges);

  if (!showMembersBadges || !badges?.length) {
    return null;
  }

  const separator = ' • ';
  return (
    <span data-hook={MEMBERS_BADGES}>{`${separator}${badges.join(
      separator,
    )}`}</span>
  );
};
