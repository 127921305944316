import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import {
  useController,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { EmptyState } from 'Group/Widget/EmptyState/EmptyState';

import { EFeedStatus } from 'FeedWidget/store/feed/types';
import { feedSelectors } from 'FeedWidget/store/feed/adapter';
import { IFeedItem } from 'FeedWidget/api/types';
import settingsParams from 'FeedWidget/settingsParams';

import { useDidUpdate } from 'common/hooks';

import { IControllerActions } from '../../types';

import * as virtual from './virtual';
import { FeedItem, FeedItemSkeleton } from './FeedItem';
import { feedSelector } from './selectors';

interface IFeedProps {
  groupId: string;

  onGuard(): void;
}

export function Feed(props: IFeedProps) {
  const { groupId, onGuard } = props;
  const { isPreview, isSSR } = useEnvironment();

  const { methods } = useController() as IControllerActions;
  const settings = useSettings();
  const { t } = useTranslation();

  const state = useSelector(feedSelector);
  const postsToShow = settings.get(settingsParams.postsCount);
  const limit = !!postsToShow;

  const items = feedSelectors.selectAll(state);
  const hasMore = state.nextCursor;

  const computeItemKey = useCallback(
    (index: number, item: IFeedItem) => item.feedItemId!,
    [],
  );

  useDidUpdate(() => {
    methods.feed.fetch(groupId, postsToShow);
  }, [groupId, isPreview, postsToShow]);

  if ([EFeedStatus.init, EFeedStatus.loading].includes(state.status)) {
    return (
      <>
        <FeedItemSkeleton />
        <FeedItemSkeleton />
        <FeedItemSkeleton />
      </>
    );
  }

  if (!items.length) {
    return (
      <EmptyState
        title={t('groups-web.feed.empty.label')}
        content={t('groups-web.feed.empty.description')}
        boxProps={{
          withSideBorders: true,
        }}
        button={{
          label: t('groups-web.go-to-group.label'),
          onClick: methods.group.redirectToGroup,
        }}
      />
    );
  }

  return (
    <Virtuoso
      useWindowScroll
      data={items}
      endReached={handleLoadMore}
      computeItemKey={computeItemKey}
      initialItemCount={isSSR ? items.length : undefined}
      overscan={300}
      totalCount={postsToShow}
      components={virtual}
      itemContent={(_, item: IFeedItem) => (
        <FeedItem onGuard={onGuard} item={item} contextId={state.groupId!} />
      )}
    />
  );

  function handleLoadMore() {
    if (hasMore && !limit) {
      methods.feed.fetchMore(10);
    }
  }
}
