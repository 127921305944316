import React from 'react';
import { TPAComponentsConsumer, Text, TextTypography } from 'wix-ui-tpa';

import { TPAModal, TPAModalProps } from './TPAModal';
import { st, classes } from './Modal.st.css';

type IModal<P> = React.FC<P> & {
  Buttons: React.FC;
  Header: React.FC<ModalHeaderProps>;
};

export enum ModalSkin {
  REACTIONS = 'reactions',
  NEW_POST = 'newPost',
}

interface ModalHeaderProps {
  title: string;
  subtitle?: string;
}

export interface ModalProps extends TPAModalProps {
  className?: string;
  skin?: ModalSkin;
}

export const Modal: IModal<ModalProps> = (props) => (
  <TPAComponentsConsumer>
    {({ mobile }) => {
      const { skin, inFullScreen, ...rest } = props;

      return (
        <TPAModal
          inFullScreen={inFullScreen || mobile}
          {...rest}
          className={st(classes.root, { skin, mobile } as any, rest.className)}
        />
      );
    }}
  </TPAComponentsConsumer>
);

Modal.displayName = 'Modal';
Modal.Buttons = (props) => (
  <div className={classes.buttons}>{props.children}</div>
);

Modal.Header = ({ title, subtitle }) => (
  <div className={classes.header}>
    <Text
      typography={TextTypography.smallTitle}
      className={classes.title}
      tagName="h1"
    >
      {title}
    </Text>
    {subtitle ? (
      <Text
        typography={TextTypography.runningText}
        className={classes.subtitle}
        tagName="h2"
      >
        {subtitle}
      </Text>
    ) : null}
  </div>
);
